import React from "react";
import { Link } from "react-router-dom";
import { useTheme } from "../ThemeContext";
import "../styles/global.css";

const SillyProjects = () => {
    const { isDarkMode } = useTheme();
    return (
        <div className="wrapper">
            <div className={`page-container ${isDarkMode ? "dark" : "light"}`}>
                <h1>Silly Projects</h1>
                <Link to="/timer" className={`link ${isDarkMode ? "dark" : "light"}`}>Board Game Timer</Link>
            </div>
        </div>
    );
};

export default SillyProjects;
