import React, { useState, useEffect } from 'react';
import '../styles/Timer.css';

const Timer = () => {
  const [time, setTime] = useState(0); // Time in seconds
  const [isRunning, setIsRunning] = useState(false);
  const [selectedGame, setSelectedGame] = useState('');
  const [backgroundMusic, setBackgroundMusic] = useState(null);
  const [customTime, setCustomTime] = useState(0);
  const [customMusic, setCustomMusic] = useState(null);
  const [endingSound, setEndingSound] = useState(null);
  const [enableWarnings, setEnableWarnings] = useState(false);
  const [warningSounds, setWarningSounds] = useState([]);
  const [backgroundAudio, setBackgroundAudio] = useState(null);
  const [audioCurrentTime, setAudioCurrentTime] = useState(0);

  // Define all sounds
  const backgroundSounds = [
    // { label: "Bell", value: `sounds/bell.mp3`, duration: 5 },
    // { label: "Chime", value: `sounds/chime.mp3`, duration: 10 },
    // { label: "Alert", value: `sounds/alert.mp3`, duration: 7 },
    // { label: "Finished Bell", value: `sounds/finished_bell.wav`, duration: 6 },
    { label: "Exciting Music 01", value: `sounds/exciting-music-01.mp3`, duration: 146 },
  ];

  const warningSoundOptions = [
    // { label: "Bell", value: `sounds/bell.mp3`, duration: 5 },
    // { label: "Chime", value: `sounds/chime.mp3`, duration: 10 },
    // { label: "Alert", value: `sounds/alert.mp3`, duration: 7 },
    // { label: "Finished Bell", value: `sounds/finished_bell.wav`, duration: 6 },
    // { label: "Exciting Music 01", value: `sounds/exciting-music-01.mp3`, duration: 146 },
    {label: "5 seconds", value: `sounds/5secs.mp3`, duration: 1},
    {label: "10 seconds", value: `sounds/10secs.mp3`, duration: 1},
    {label: "30 seconds", value: `sounds/30secs.mp3`, duration: 1},
    {label: "1 minute", value: `sounds/1min.mp3`, duration: 1},
    {label: "1 min 30s", value: `sounds/1m30s.mp3`, duration: 1},
  ];

  const endingSounds = [
    // { label: "Bell", value: `sounds/bell.mp3`, duration: 5 },
    // { label: "Chime", value: `sounds/chime.mp3`, duration: 10 },
    { label: "Buzzer 01", value: `sounds/buzzer.mp3`, duration: 1 },
    { label: "Finished Bell", value: `sounds/finished_bell.wav`, duration: 6 },
  ];

  // Predefined game settings
  const gameSettings = {
    "Fit to Print": { time: 240, music: { value: `sounds/exciting-music-01.mp3`, duration: 146 } },
  };

  // Add a new game
  const addGame = (name, time, music) => {
    gameSettings[name] = { time, music };
  };

  // Add new warning sound
  const addWarningSound = () => {
    setWarningSounds([...warningSounds, { sound: null, time: 0 }]);
  };

  const updateWarningSound = (index, field, value) => {
    const updatedSounds = [...warningSounds];
    updatedSounds[index][field] = value;
    setWarningSounds(updatedSounds);
  };

  const removeWarningSound = (index) => {
    const updatedSounds = [...warningSounds];
    updatedSounds.splice(index, 1);
    setWarningSounds(updatedSounds);
  };

  // Play a specific sound dynamically
  const playDynamicSound = (sound, timerTime, soundDuration) => {
    if (!sound || timerTime <= 0 || soundDuration <= 0) return null;
    const audio = new Audio(sound.value);
    const startTime = Math.max(0, soundDuration - timerTime);
    audio.currentTime = startTime;
    audio.play();
    return audio;
  };

  const startTimer = () => {
    if (!selectedGame && customTime <= 0) return;

    let timerDuration = customTime;
    let timerMusic = customMusic;

    if (selectedGame && selectedGame !== "Other") {
      const settings = gameSettings[selectedGame];
      timerDuration = settings.time;
      timerMusic = settings.music;
    }

    setTime(timerDuration);
    if (timerMusic) {
      const newBackgroundAudio = playDynamicSound(timerMusic, timerDuration, timerMusic.duration);
      setBackgroundAudio(newBackgroundAudio);
    }

    setIsRunning(true);
  };

  const resumeTimer = () => {
    if (backgroundAudio) {
      backgroundAudio.currentTime = audioCurrentTime;
      backgroundAudio.play();
    }
    setIsRunning(true);
  };

  const stopTimer = () => {
    setIsRunning(false);
    if (backgroundAudio) {
      backgroundAudio.pause();
      backgroundAudio.currentTime = 0;
    }
  };

  const pauseTimer = () => {
    setIsRunning(false);
    if (backgroundAudio) {
      setAudioCurrentTime(backgroundAudio.currentTime);
      backgroundAudio.pause();
    }
  };

  const restartTimer = () => {
    let timerDuration = customTime;
    let timerMusic = customMusic;

    if (selectedGame && selectedGame !== "Other") {
      const settings = gameSettings[selectedGame];
      timerDuration = settings.time;
      timerMusic = settings.music;
    }

    setTime(timerDuration);
    if (timerMusic) {
      if (backgroundAudio) {
        backgroundAudio.pause();
        backgroundAudio.currentTime = 0;
      }
      const newBackgroundAudio = playDynamicSound(timerMusic, timerDuration, timerMusic.duration);
      setBackgroundAudio(newBackgroundAudio);
    }

    setIsRunning(true);
  };

  useEffect(() => {
    let timer;
    if (isRunning && time > 0) {
      timer = setInterval(() => {
        setTime((prevTime) => {
          const currentTime = prevTime - 1;

          if (enableWarnings) {
            warningSounds.forEach(({ sound, time: warningTime }) => {
              if (currentTime === warningTime && sound) {
                const audio = new Audio(sound.value);
                audio.play();
              }
            });
          }

          return currentTime;
        });
      }, 1000);
    } else if (time === 0 && isRunning) {
      setIsRunning(false);
      if (endingSound) {
        const audio = new Audio(endingSound.value);
        audio.play();
      }
    }

    return () => clearInterval(timer);
  }, [isRunning, time, warningSounds, enableWarnings, endingSound]);

  const timeOptions = [
    { label: "0 seconds", value: 0 },
    { label: "5 seconds", value: 5 },
    { label: "10 seconds", value: 10 },
    { label: "20 seconds", value: 20 },
    { label: "30 seconds", value: 30 },
    { label: "1 minute", value: 60 },
    { label: "1 minute 30 seconds", value: 90 },
    { label: "2 minutes", value: 120 },
    { label: "2 minutes 30 seconds", value: 150 },
    { label: "3 minutes", value: 180 },
    { label: "3 minutes 30 seconds", value: 210 },
    { label: "4 minutes", value: 240 },
    { label: "4 minutes 30 seconds", value: 270 },
    { label: "5 minutes", value: 300 },
    { label: "10 minutes", value: 600 },
    { label: "20 minutes", value: 1200 },
  ];

  return (
    <div className="timer-wrapper">
      <div className="timer-container">
        <h1>Board Game Timer</h1>
        <p>Use this timer to manage your board game sessions. Select a predefined game or set a custom time and music. You can enable warning sounds to alert you at specific intervals and choose an ending sound to play when the timer reaches zero. Use the controls to start, pause, stop, or restart the timer.</p>
        <div className="settings">
          <label>
            Select Game:
            <select value={selectedGame} onChange={(e) => setSelectedGame(e.target.value)}>
              <option value="">--Choose a Game--</option>
              {Object.keys(gameSettings).map((game) => (
                <option key={game} value={game}>{game}</option>
              ))}
              <option value="Other">Other</option>
            </select>
          </label>
          {selectedGame === "Other" && (
            <div className="custom-settings">
              <label>
                Custom Time:
                <select value={customTime} onChange={(e) => setCustomTime(Number(e.target.value))}>
                  {timeOptions.map(option => (
                    <option key={option.value} value={option.value}>{option.label}</option>
                  ))}
                </select>
              </label>
              <label>
                Custom Music:
                <select value={customMusic?.value || ""} onChange={(e) => setCustomMusic(backgroundSounds.find(s => s.value === e.target.value))}>
                  <option value="">--Choose Music--</option>
                  {backgroundSounds.map(sound => (
                    <option key={sound.value} value={sound.value}>{sound.label}</option>
                  ))}
                </select>
              </label>
            </div>
          )}
        </div>

        <div className="advanced-settings">
          <h2>Advanced Settings</h2>
          <label>
            Enable Warning Sounds:
            <input
              type="checkbox"
              checked={enableWarnings}
              onChange={(e) => setEnableWarnings(e.target.checked)}
            />
          </label>
          {enableWarnings && (
            <div className="warning-sounds">
              {warningSounds.map((warning, index) => (
                <div key={index}>
                  <label>
                    Warning Sound:
                    <select
                      value={warning.sound?.value || ""}
                      onChange={(e) => updateWarningSound(index, "sound", warningSoundOptions.find(s => s.value === e.target.value))}
                    >
                      <option value="">--Choose Sound--</option>
                      {warningSoundOptions.map(sound => (
                        <option key={sound.value} value={sound.value}>{sound.label}</option>
                      ))}
                    </select>
                  </label>
                  <label>
                    Time:
                    <select
                      value={warning.time}
                      onChange={(e) => updateWarningSound(index, "time", Number(e.target.value))}
                    >
                      {timeOptions
                        .filter((option) => option.value < (selectedGame && gameSettings[selectedGame] ? gameSettings[selectedGame].time : customTime))
                        .map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                    </select>
                  </label>
                  <button onClick={() => removeWarningSound(index)}>Remove</button>
                </div>
              ))}
              <button onClick={addWarningSound}>Add Warning Sound</button>
            </div>
          )}
          <label>
            Ending Sound:
            <select value={endingSound?.value || ""} onChange={(e) => setEndingSound(endingSounds.find(s => s.value === e.target.value))}>
              <option value="">--Choose Sound--</option>
              {endingSounds.map(sound => (
                <option key={sound.value} value={sound.value}>{sound.label}</option>
              ))}
            </select>
          </label>
        </div>

        <div className="timer-display">
          <span>{String(Math.floor(time / 60)).padStart(2, '0')}:</span>
          <span>{String(time % 60).padStart(2, '0')}</span>
        </div>

        <div className="controls">
          <button onClick={isRunning ? pauseTimer : resumeTimer}>{isRunning ? "Pause" : "Start"}</button>
          <button onClick={stopTimer}>Stop</button>
          <button onClick={restartTimer}>Restart</button>
        </div>
        <div className="credits">
          <h2>Credits</h2>
          <p>Exciting Music 01 aka:</p>
          <p>
            "Sergio's Magic Dustbin" Kevin MacLeod (incompetech.com)
            <br />
            Licensed under Creative Commons: By Attribution 4.0 License
            <br />
            http://creativecommons.org/licenses/by/4.0/
          </p>
        </div>
      </div>
    </div>
  );
};

export default Timer;
