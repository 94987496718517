import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import Home from "./pages/Home";
import About from "./pages/About";
import BoardGames from "./pages/BoardGames";
import SillyProjects from "./pages/SillyProjects";
import Timer from "./pages/Timer";
import { useTheme } from "./ThemeContext";

import "./styles/global.css";

const App = () => {
  const { isDarkMode } = useTheme();

  useEffect(() => {
    document.body.className = isDarkMode ? "dark" : "light";
  }, [isDarkMode]);

  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/boardgames" element={<BoardGames />} />
        <Route path="/sillyprojects" element={<SillyProjects />} />
        <Route path="/timer" element={<Timer />} />
      </Routes>
    </Router>
  );
};

export default App;
