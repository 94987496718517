import React from "react";
import { useTheme } from "../ThemeContext";
import "../styles/global.css";

const About = () => {
    const { isDarkMode } = useTheme();
    return (
        <div className="wrapper">
            <div className={`page-container ${isDarkMode ? "dark" : "light"}`}>
                <h1>About Me - WIP</h1>
                <p>Truly, this site is just for me to host my silly little projects</p>
            </div>
        </div>
    );
};

export default About;
