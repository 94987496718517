import React from "react";
import { useTheme } from "../ThemeContext";
import "../styles/global.css";

const Home = () => {
    const { isDarkMode } = useTheme();
    return (
        <div className="wrapper">
            <div className={`page-container ${isDarkMode ? "dark" : "light"}`}>
                <header className="header">
                    <h1 className="title">Welcome to My Website</h1>
                    <p className="subtitle">
                        Work in progress. Check back soon for updates!
                    </p>
                    <h5 className="subtitle">I'm a backend dev...</h5>
                </header>
                {/* <div className="links">
                    <a href="/about" className={`link ${isDarkMode ? "dark" : "light"}`}>
                        About Me
                    </a>
                    
                    <a
                        href="http://www.keyoni.wordpress.com"
                        className={`link ${isDarkMode ? "dark" : "light"}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Visit My (Old) Blog
                    </a>
                </div> */}
            </div>
        </div>
    );
};

export default Home;
