import React from "react";
import { Link } from "react-router-dom";
import { useTheme } from "../ThemeContext";
import "../styles/navbar.css";

const Navbar = () => {
    const { isDarkMode, toggleTheme } = useTheme();
    return (
        <div className={`nav-container ${isDarkMode ? "dark" : "light"}`}>
            <nav className={`nav ${isDarkMode ? "dark" : "light"}`}>
                <div className="nav-links">
                    <Link to="/">Home</Link>
                    <Link to="/about">About</Link>
                    <Link to="/boardgames">Board Games</Link>
                    <Link to="/sillyprojects">Silly Projects</Link>
                </div>
                <button
                    onClick={toggleTheme}
                    className={`toggle-btn ${isDarkMode ? "dark" : "light"}`}
                >
                    Toggle Theme
                </button>
            </nav>
        </div>
    );
};
export default Navbar;
