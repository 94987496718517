import React from "react";
import { useTheme } from "../ThemeContext";
import "../styles/global.css";

const BoardGames = () => {
    const { isDarkMode } = useTheme();
    return (
        <div className="wrapper">
            <div className={`page-container ${isDarkMode ? "dark" : "light"}`}>
                <h1>Board Games</h1>
                <p>This is the Board Games page.</p>
                <a href="https://boardgamegeek.com/user/TheDreamerKey" target="_blank" rel="noopener noreferrer" className={`link ${isDarkMode ? "dark" : "light"}`}>
                    <button className={`link ${isDarkMode ? "dark" : "light"}`}>Visit My BoardGameGeek</button>
                </a>
                <div style={{ border: "1px solid", padding: "10px", marginTop: "20px" }}>
                    <h2>Last Played</h2>
                    <p>Game Name: Fit to Print </p>
                </div>
            </div>
        </div>
    );
};

export default BoardGames;
